import {
  BooleanField,
  BooleanInput,
  Button,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  email,
  FilterButton,
  ImageInput,
  Labeled,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext,
  usePermissions,
  useRecordContext,
} from 'react-admin';
// import Thumbnail from "../components/Thumbnail";
import IconExport from '@mui/icons-material/GetApp';
import NoteIcon from '@mui/icons-material/Notes';
import {
  Box,
  Button as MButton,
  CircularProgress,
  IconButton,
  TextField as MTextField,
  Tooltip,
} from '@mui/material';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useParams} from 'react-router';
import {
  AuthProvider,
  AuthProviderNames,
  HandType,
  HandTypeNames,
  IUser,
  RoleType,
  RoleTypeNames,
  UserState,
  UserStateNames,
} from '../common/types';
import PreviewImage from '../components/PreviewImage';
import Section from '../components/Section';
import ThumbnailField from '../components/ThumbnailField';
import {Api} from '../utils/api';
import {fileDownload} from '../utils/fileDownload';
import useApp from '../hooks/useApp';

const UserFilter = [
  <TextInput label="ID, SUB ID, 이름, 핸드폰, 이메일" source="q" alwaysOn />,
  <SelectInput
    label="역할"
    source="role"
    choices={Object.values(RoleType).map(value => ({
      id: value,
      name: RoleTypeNames[value],
    }))}
    alwaysOn
  />,
  <SelectInput
    label="상태"
    source="state"
    choices={Object.values(UserState).map(value => ({
      id: value,
      name: UserStateNames[value],
    }))}
    alwaysOn
  />,
  <BooleanInput label="관심회원" source="attention" />,
  <BooleanInput label="활성트레이너" source="activeTrainer" />,
  <BooleanInput label="활성지공사" source="activeDriller" />,
  <BooleanInput label="테스트계정" source="experiment" />,
  <BooleanInput label="마케팅동의" source="enableMarket" />,
];

export const MemoButton = (props: any) => {
  const record = useRecordContext(props);
  if (!record) return null;
  return record.memo ? (
    <Tooltip title={record.memo}>
      <IconButton>
        <NoteIcon />
      </IconButton>
    </Tooltip>
  ) : null;
};

const ListActions = () => {
  const {permissions} = usePermissions();
  const [loading, setLoading] = React.useState(false);
  const {filterValues, sort} = useListContext();

  const realsort = (sort.order === 'DESC' ? '-' : '') + sort.field;

  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />

      {permissions === RoleType.ADMIN && (
        <Button
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true);
              const res = await Api.get(
                `/users?download=true&offset=0&limit=0&filter=${encodeURIComponent(
                  JSON.stringify({
                    ...filterValues,
                  }),
                )}&sort=${realsort}`,
                {
                  timeout: 60 * 1000,
                  responseType: 'blob',
                },
              );
              const filename = `회원.xlsx`;
              fileDownload(res, filename);
            } catch (err) {
              // notify(err, "warning");
            } finally {
              setLoading(false);
            }
          }}
          label="엑셀">
          {loading ? (
            <CircularProgress size={18} thickness={2} />
          ) : (
            <IconExport />
          )}
        </Button>
      )}
    </TopToolbar>
  );
};

export const UserList = () => (
  <List
    actions={<ListActions />}
    filters={UserFilter}
    sort={{field: 'createdAt', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <ThumbnailField label="사진" source="photo" />

      {/* <SelectField
        label="인증"
        source="authProvider"
        choices={Object.values(AuthProvider).map((value) => ({
          id: value,
          name: AuthProviderNames[value],
        }))}
      /> */}

      <TextField label="이름" source="name" />

      <TextField label="SUB ID" source="subUsername" />
      <TextField label="픽스코어ID" source="pickScoreId" />
      <TextField label="전화번호" source="phone" />
      <SelectField
        label="역할"
        source="role"
        choices={Object.values(RoleType).map(value => ({
          id: value,
          name: RoleTypeNames[value],
        }))}
      />
      <SelectField
        label="상태"
        source="state"
        choices={Object.values(UserState).map(value => ({
          id: value,
          name: UserStateNames[value],
        }))}
      />
      <BooleanField label="활성트레이너" source="activeTrainer" />
      <BooleanField label="활성지공사" source="activeDriller" />
      <BooleanField label="관심회원" source="attention" />
      <MemoButton label="메모" source="memo" />
      <BooleanField label="마케팅동의" source="enableMarket" />
      <DateField label="가입일" source="createdAt" showTime={true} />
      <DateField label="접속일" source="accessedAt" showTime={true} />
      <BooleanField label="테스트계정" source="experiment" />
    </Datagrid>
  </List>
);

const UserTitle = () => {
  const record = useRecordContext<IUser>();
  return <span>회원 {record ? `"${record.name}"` : ''}</span>;
};

const UserEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton />
      <Box
        component="span"
        sx={{
          marginLeft: 4,
          whiteSpace: 'pre-wrap',
          fontSize: 12,
          color: 'black',
        }}>
        * 보기전용 어드민은 메모,관심회원,주민등록번호 편집가능
      </Box>
    </Toolbar>
  );
};

const SendMessageField = () => {
  type FormData = {
    message: string;
  };

  const {permissions} = usePermissions();

  const record = useRecordContext<IUser>();
  const [loading, setLoading] = useState(false);
  const {control, handleSubmit} = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    if (data.message === '') return;
    setLoading(true);
    try {
      await Api.post('/send-message', {text: data.message, user: record.id});
      alert('전송하였습니다.');
    } catch (err) {
      // @ts-ignore
      alert(err['message']);
    } finally {
      setLoading(false);
    }
  };

  if (permissions !== RoleType.ADMIN) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: 10,
      }}>
      <Controller
        control={control}
        name="message"
        defaultValue=""
        rules={{
          required: '필수 항목입니다.',
        }}
        render={({field: {onChange, onBlur, value}}) => (
          <MTextField
            sx={{width: 500}}
            placeholder="메시지"
            onBlur={onBlur}
            value={value}
            onChange={onChange}
          />
        )}
      />

      <MButton
        variant="text"
        disabled={loading}
        sx={{width: 150}}
        onClick={handleSubmit(onSubmit)}>
        {loading && <CircularProgress size={18} thickness={2} />}
        메시지 전송
      </MButton>
    </Box>
  );
};

const PartnerField = () => {
  const record = useRecordContext();
  const activeText = record.enabled ? '(활성)' : '';
  return <>{record.name + activeText}</>;
};

export const UserEdit = () => {
  const {id} = useParams<'id'>();

  const {banks} = useApp();

  return (
    <Edit title={<UserTitle />}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="가입일" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="accessedAt" label="접속일" showTime={true} />
        </Labeled>
        <SelectInput
          label="상태"
          source="state"
          choices={Object.values(UserState).map(value => ({
            id: value,
            name: UserStateNames[value],
          }))}
          validate={[required()]}
        />

        <SelectInput
          label="역할"
          source="role"
          choices={[
            {
              id: RoleType.ADMIN,
              name: RoleTypeNames[RoleType.ADMIN],
              disabled: false,
            },
            {
              id: RoleType.ADMIN_OBSERVER,
              name: RoleTypeNames[RoleType.ADMIN_OBSERVER],
              disabled: false,
            },
            {
              id: RoleType.USER,
              name: RoleTypeNames[RoleType.USER],
              disabled: false,
            },
            {
              id: RoleType.PARTNER,
              name: RoleTypeNames[RoleType.PARTNER],
              disabled: false,
            },
          ]}
        />

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField
            label="트레이너"
            source="trainer"
            reference="trainers">
            <PartnerField />
          </ReferenceField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="프로샵" source="driller" reference="drillers">
            <PartnerField />
          </ReferenceField>
        </Labeled>

        <BooleanInput label="관심회원" source="attention" />
        <TextInput label="메모" source="memo" multiline fullWidth />

        <BooleanInput label="테스트계정" source="experiment" />

        <Labeled sx={{marginBottom: 4}}>
          <SelectInput
            label="인증타입"
            source="authProvider"
            choices={[
              {
                id: AuthProvider.KAKAO,
                name: AuthProviderNames[AuthProvider.KAKAO],
                disabled: true,
              },
              {
                id: AuthProvider.NAVER,
                name: AuthProviderNames[AuthProvider.NAVER],
                disabled: true,
              },
              {
                id: AuthProvider.EMAIL,
                name: AuthProviderNames[AuthProvider.EMAIL],
                disabled: false,
              },
              {
                id: AuthProvider.FACEBOOK,
                name: AuthProviderNames[AuthProvider.FACEBOOK],
                disabled: true,
              },
              {
                id: AuthProvider.GOOGLE,
                name: AuthProviderNames[AuthProvider.GOOGLE],
                disabled: true,
              },
              {
                id: AuthProvider.APPLE,
                name: AuthProviderNames[AuthProvider.APPLE],
                disabled: true,
              },
            ]}
          />
        </Labeled>

        <TextInput
          label="ID(email)"
          source="username"
          fullWidth
          helperText="인증타입이 이메일인 경우만 변경 가능, 패스워드도 반드시 같이 설정해주세요"
        />
        <TextInput label="패스워드(변경)" source="password" fullWidth />

        <TextInput label="SUB ID" source="subUsername" fullWidth disabled />
        <TextInput label="이름" source="name" fullWidth />
        <TextInput label="전화번호" source="phone" fullWidth />

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="가상전화번호" source="virtualPhone" fullWidth />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="성별" source="gender" fullWidth />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="생년월일" source="birthday" fullWidth />
        </Labeled>

        <TextInput label="Email" source="email" fullWidth />

        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="구력" source="career" fullWidth />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="에버리지" source="average" fullWidth />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <SelectField
            label="사용손"
            source="hand"
            choices={Object.values(HandType).map(value => ({
              id: value,
              name: HandTypeNames[value],
            }))}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField
            label="스타일"
            source="pitchStyle"
            reference="categories"
            link={false}>
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField source="leftAt" label="탈퇴일" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <TextField label="탈퇴사유" source="leftReason" fullWidth />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceManyField
            label="카드"
            target="user"
            reference="cards"
            sortable={false}
            filter={{user: id}}
            pagination={<Pagination />}
            fullWidth>
            <Datagrid bulkActionButtons={false}>
              <TextField label="pg" source="provider" />
              <TextField label="카드번호" source="cardNum" />
              <TextField label="카드회사" source="cardCompany" />
              <BooleanField label="개인카드" source="isPersonal" />

              <DateField label="등록시간" source="createdAt" showTime={true} />
            </Datagrid>
          </ReferenceManyField>
        </Labeled>
        <Section title="픽스코어" />
        <Labeled sx={{marginBottom: 4}}>
          <TextField label="픽스코어ID" source="pickScoreId" />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="평균" source="pickRecord.averageScore" />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="총게임수" source="pickRecord.totalGameCount" />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="최고점수" source="pickRecord.maxScore" />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="최저점수" source="pickRecord.minScore" />
        </Labeled>

        <Section title="채팅상태정보" />
        <Labeled sx={{marginBottom: 4}}>
          <BooleanField label="채팅알림" source="enableNotiTalk" />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <BooleanField label="연락불가능시간 설정" source="enableDisturb" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="연락불가능 시작시간" source="disturbRange.0" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="연락불가능 종료시간" source="disturbRange.1" />
        </Labeled>

        <TextInput
          label="연락주의사항"
          source="contactMemo"
          multiline
          fullWidth
        />

        <Labeled sx={{marginBottom: 4}}>
          <NumberField
            label="평균응답시간(분)"
            source="averageResponseMinutes"
          />
        </Labeled>

        <Section title="정산정보" />

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="예금주" source="bankAccountName" fullWidth />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <SelectField
            label="은행"
            source="bankCode"
            choices={
              banks?.map((bank: any) => ({
                id: bank.code,
                name: bank.name,
              })) || []
            }
          />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <TextField label="계좌번호" source="bankAccountNumber" fullWidth />
        </Labeled>

        <ImageInput
          source="bankPhotos"
          label="신분증"
          accept="image/*"
          multiple>
          <PreviewImage source="src" />
        </ImageInput>

        <Labeled sx={{marginBottom: 4}}>
          <TextInput
            label="주민등록번호"
            source="registrationNumber"
            fullWidth
          />
        </Labeled>
        <SendMessageField />

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceManyField
            label="접속 디바이스"
            target="user"
            reference="devices"
            sortable={false}
            filter={{user: id}}
            pagination={<Pagination />}
            fullWidth>
            <Datagrid>
              <TextField label="app" source="app" />
              <TextField label="version" source="version" />
              <TextField label="ip" source="ip" />
              <TextField label="browser" source="browser" />
              <TextField label="os" source="os" />
              <TextField
                label="pushToken"
                source="pushToken"
                // cellClassName={classes.comment}
              />
              <TextField label="deviceId" source="deviceId" />
              <DateField
                label="토큰갱신일"
                source="updatedAt"
                showTime={true}
              />
              <DateField
                label="토큰만료일"
                source="expiresAt"
                showTime={true}
              />
            </Datagrid>
          </ReferenceManyField>
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = () => {
  const {permissions} = usePermissions();

  if (permissions !== RoleType.ADMIN) return null;

  return (
    <Create>
      <SimpleForm>
        <TextInput
          label="Email"
          source="username"
          validate={[required(), email()]}
          fullWidth
        />

        <SelectInput
          label="역할"
          source="role"
          validate={[required()]}
          choices={[
            {
              id: RoleType.ADMIN,
              name: RoleTypeNames[RoleType.ADMIN],
              disabled: false,
            },
            {
              id: RoleType.ADMIN_OBSERVER,
              name: RoleTypeNames[RoleType.ADMIN_OBSERVER],
              disabled: false,
            },
            {
              id: RoleType.USER,
              name: RoleTypeNames[RoleType.USER],
              disabled: true,
            },
            {
              id: RoleType.PARTNER,
              name: RoleTypeNames[RoleType.PARTNER],
              disabled: true,
            },
          ]}
        />

        <TextInput
          label="패스워드"
          source="password"
          validate={[required()]}
          fullWidth
        />

        <TextInput
          label="닉네임"
          source="name"
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

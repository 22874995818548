import {Card, CardContent, Grid, Typography} from '@mui/material';
import Switch from '@mui/material/Switch';
import {useState} from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  useRecordContext,
} from 'react-admin';
import {IPickDayScore, IUser} from '../common/types';
import NumberArrayField from '../components/NumberArrayField';
import {Api} from '../utils/api';

const PickDayScoreFilter = [
  <ReferenceInput source="user" reference="users" fullWidth alwaysOn>
    <AutocompleteInput
      name="filterUser" // 자동완성 방지
      optionText={(choice: IUser) => `${choice.name}(${choice.phone})`}
      label="회원"
    />
  </ReferenceInput>,
];

export const PickDayScoreList = () => (
  <List
    filters={PickDayScoreFilter}
    sort={{field: 'date', order: 'DESC'}}
    exporter={false}>
    <Datagrid
      rowClick="expand"
      expand={<PickDayScoreShow />}
      bulkActionButtons={false}>
      <ReferenceField label="회원" reference="users" source="user">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="센터" reference="centers" source="center">
        <TextField source="name" />
      </ReferenceField>
      <DateField label="날짜" source="date" showTime={false} />
      <NumberField label="총게임수" source="totalGameCount" />
      <NumberField label="평균점수" source="averageScore" />
      <NumberField label="최대점수" source="maxScore" />
      <NumberField label="최소점수" source="minScore" />
      <NumberArrayField label="점수들" source="gameScores" />
      <BooleanField label="테스트" source="experiment" />
    </Datagrid>
  </List>
);

const PickDayScoreShow = () => {
  const record = useRecordContext<IPickDayScore>();

  const [experiment, setExperiment] = useState(record?.experiment);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setExperiment(event.target.checked);
    await Api.put(`/pick-day-scores/${record.id}`, {
      experiment: event.target.checked,
    });
  };

  if (!record) return null;
  return (
    <Card sx={{width: 600, margin: 'auto'}}>
      <CardContent>
        <Grid container>
          <Typography>테스트</Typography>
          <Switch checked={experiment} onChange={handleChange} />
        </Grid>
      </CardContent>
    </Card>
  );
};

import {DateRange, ID} from '.';

/*
제목: 문자열 (예, [유튜브] 서울 오란다 볼링파크 팀 대항전)
서브제목: 문자열 (예, 스트로커-크랭커-덤리스-투핸드 팀당3명, 서든데스 경기)
경기일시: 날짜
모집기간: 날짜~날짜
센터: 센터ID
가격: 문자열(예, 참기비 50,000원\n 게임비 별도 15,000원)
에버리지제한: 문자열(예, AVG 제한없음)
성별: 다음 중 하나(남녀모두, 남자, 여자)
경기형식: 문자열(예, 팀 서바이벌)
복장: 문자열(예, 단체복)
정비패턴: 문자열(예, 볼닥패턴)
*/

export enum GameGenderTarget {
  NO_GENDER = 'no-gender',
  MALE = 'male',
  FEMALE = 'female',
}

export const GameGenderTargetNames = {
  'no-gender': '남녀모두',
  male: '남자',
  female: '여자',
};

export enum GameState {
  IDLE = 'idle',
  RECRUITMENT = 'recruitment',
  CLOSED = 'closed',
}

export const GameStateNames = {
  idle: '준비중',
  recruitment: '모집중',
  closed: '모집종료',
};

export enum GameType {
  GROUP = 'group',
  INDIVIDUAL = 'individual',
}

export const GameTypeNames = {
  group: '단체전',
  individual: '개인전',
};

export interface IGameSection {
  title: string;
  body: string;
}

export interface IGame {
  id: ID;
  createdAt: Date;
  updatedAt: Date;

  state: GameState;
  type: GameType;

  enabled: boolean;

  title: string;
  subtitle: string;
  gameAt: Date;

  acceptanceDateRange: DateRange; //모집기간
  center: ID;
  prizeText: string;
  priceText: string;
  averageText: string;
  matchFormat: string;
  wear: string;
  pattern: string;
  genderTarget: GameGenderTarget;

  sections: IGameSection[];

  countMember: number;

  isNeedAgreement: boolean;

  notice?: string;

  virtual?: {
    applied?: boolean;
  };
}

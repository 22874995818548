import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import {
  Datagrid,
  DateField,
  Edit,
  List,
  maxValue,
  minValue,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
} from 'react-admin';
import {RoleType} from '../common/types';
import Section from '../components/Section';
import useSetting from '../hooks/useSetting';

export const SettingList = () => {
  const {setting} = useSetting();

  return (
    <>
      <Box sx={{padding: 1}}>
        <a
          href="https://yongtrim.notion.site/yongtrim/032fcfddf2c2468981a4e4cfe196918b"
          target="_blank"
          rel="noreferrer">
          시스템 문서
        </a>
      </Box>
      <Box sx={{padding: 1}}>
        디비체크시간:{' '}
        {dayjs(setting?.dbCheckDate).format('YYYY-MM-DD HH:mm:ss')}
        <br />
        디비상태:{' '}
        {setting?.dbStatus === 1 ? 'OK' : `조치필요(${setting?.dbStatus})`}
        <br />
        디비 사용 size: {setting?.dbLogicalSize} M / 512M
      </Box>
      <List sort={{field: 'createdAt', order: 'DESC'}} exporter={false}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <DateField label="수정날짜" source="updatedAt" showTime={true} />
        </Datagrid>
      </List>
    </>
  );
};

//
// Edit
const SettingTitle = () => {
  return <span>설정</span>;
};

const SettingEditToolbar = () => {
  const {permissions} = usePermissions();
  return <Toolbar>{permissions === RoleType.ADMIN && <SaveButton />}</Toolbar>;
};

export const SettingEdit = () => (
  <Edit title={<SettingTitle />}>
    <SimpleForm toolbar={<SettingEditToolbar />}>
      <Section title="시스템" />

      <TextInput label="전자우편" source="email" fullWidth />

      <TextInput
        label="이노페이 취소 비밀번호"
        source="innopayCancelPassword"
        fullWidth
        helperText="잘못된 비밀번호인 경우 앱에서 취소 불가"
      />

      <TextInput
        label="버전"
        source="version"
        fullWidth
        helperText="강제업데이트 기능, 구글스토어, 앱스토어에 해당 버전이 출시된 것 확인 후 변경해 주세요"
      />
      <TextInput label="App store(iOS) URL" source="appIosUrl" fullWidth />
      <TextInput
        label="Play store(안드로이드) URL"
        source="appAndroidUrl"
        fullWidth
      />

      <TextInput
        label="공지사항"
        source="notice"
        fullWidth
        multiline
        helperText="서버 중단시 firebase console의 remote config에서 설정 가능합니다."
      />

      <Section title="회사" />

      <TextInput label="통신판매번호" source="company.tongsinNo" fullWidth />
      <TextInput label="사업자등록번호" source="company.bizNum" fullWidth />
      <TextInput label="상호" source="company.name" fullWidth />
      <TextInput label="대표자명" source="company.owner" fullWidth />
      <TextInput label="사업장소재지" source="company.address" fullWidth />
      <TextInput label="메일문의" source="company.email" fullWidth />
      <TextInput label="고객센터" source="company.phone" fullWidth />

      <Section title="결제" />

      <NumberInput
        label="맛보기 수수료율(%)"
        source="trainerSettlementRate"
        fullWidth
        helperText="수수료를 제외하고 파트너에게 정산 (맛보기레슨,맛보기레슨, 모집그룹레슨(구)), 변경하면 새로운 결제건부터 적용됨"
        validate={[required(), minValue(1), maxValue(99)]}
      />

      <NumberInput
        label="일반 수수료율(%)"
        source="trainerNormalSettlementRate"
        fullWidth
        helperText="수수료를 제외하고 파트너에게 정산 (일반레슨, 그룹레슨), 변경하면 새로운 결제건부터 적용됨"
        validate={[required(), minValue(1), maxValue(99)]}
      />

      <NumberInput
        label="수업 유효기한(맛보기수업, 맛보기지공, 일반레슨, 그룹레슨)"
        source="trialExpirationDays"
        fullWidth
        validate={[required(), minValue(1)]}
        helperText="변경하면 새로운 결제건부터 적용됨"
      />

      <NumberInput
        label="재결제 할인 유효기한"
        source="normalDiscountExpirationDays"
        fullWidth
        helperText="일반레슨, 그룹레슨"
        validate={[required(), minValue(1)]}
      />

      <NumberInput
        label="트레이너 월간 금액"
        source="trainerMonthlyPrice"
        fullWidth
        validate={[required(), minValue(1)]}
      />

      <NumberInput
        label="프로샵 월간 금액"
        source="drillerMonthlyPrice"
        fullWidth
        validate={[required(), minValue(1)]}
      />

      <NumberInput
        label="트레이너 무료 개월수"
        source="trainerFreeMonths"
        fullWidth
        validate={[required(), minValue(1)]}
      />
      <NumberInput
        label="프로샵 무료 개월수"
        source="drillerFreeMonths"
        fullWidth
        validate={[required(), minValue(1)]}
      />
      <NumberInput
        label="그룹레슨 예약금"
        source="lessonFee"
        fullWidth
        validate={[required(), minValue(1)]}
      />

      <NumberInput
        label="볼닥앤플 다운로드 가격"
        source="priceDownloadMovie"
        fullWidth
        validate={[required(), minValue(1)]}
      />

      <Section title="약관" />

      <TextInput label="서비스 이용약관" source="policyTosUrl" fullWidth />
      <TextInput
        label="개인정보 처리방침"
        source="policyPrivacyUrl"
        fullWidth
      />
      <TextInput
        label="위치기반 서비스 이용약관"
        source="policyLocationUrl"
        fullWidth
      />
      <TextInput
        label="전자금융거래 이용약관"
        source="policyFinancialUrl"
        fullWidth
      />
      <TextInput label="정기결제 약관" source="policyMembershipUrl" fullWidth />
      <TextInput label="정산정책" source="policySettlementUrl" fullWidth />
      <TextInput
        label="볼닥앤플 AI 서비스 약관"
        source="policyFaceswapUrl"
        fullWidth
      />
    </SimpleForm>
  </Edit>
);

// common

export type ID = any; //mongoose.Schema.Types.ObjectId | string;
// export type ID = string;

export type Photo = string;
export type Video = string;
export type PdfDocument = string;
export type Day = string; // YYYY.MM.dd
export type MonthDay = string; //MM.dd
export type Regions = string[]; //[0:동면 1:시 2:도]

export interface VideoInfo {
  video: Video;
  thumb: Photo;
  duration?: number; // ms
  size: number; // bytes
}

export interface VideoExInfo {
  video: Video;
  thumb: Photo;
  duration: number; // ms
  size: number; // bytes
  resolutionX: number;
  resolutionY: number;
  mediaType: string;
}

export enum PhotoType {
  PROFILE = 'PROFILE',
  PHOTO = 'PHOTO',
  LIST_COVER = 'LIST_COVER',
  ICON = 'ICON',
  BANNER = 'BANNER',
  DETAIL = 'DETAIL',
  POPUP = 'POPUP',
}
//
// User
export enum RoleType {
  ADMIN = 'admin',
  ADMIN_OBSERVER = 'admin-observer',
  USER = 'user',
  PARTNER = 'partner',
}

export const RoleTypeNames = {
  admin: '어드민',
  'admin-observer': '보기전용 어드민',
  user: '일반회원',
  partner: '파트너',
};

export type AuthUser = {
  id: string;
  role: RoleType;
  username: string;
  experiment: boolean;
};

export enum GenderTarget {
  NO_GENDER = 'no-gender',
  MALE = 'male',
  FEMALE = 'female',
}

export const GenderTargetNames = {
  'no-gender': '전체회원',
  male: '남자회원',
  female: '여자회원',
};

export enum ShopType {
  INNER = 'inner',
  OUTER = 'outer',
}
export const ShopTypeNames = {
  inner: '내부샵',
  outer: '외부샵',
};

export interface Coordinate {
  lng: number;
  lat: number;
}

export enum Weekday {
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
  SUN = 7,
}

export const WeekdayNames = {
  1: '월',
  2: '화',
  3: '수',
  4: '목',
  5: '금',
  6: '토',
  7: '일',
};

export interface NaverAddressInfo {
  jibunAddress: string;
  roadAddress: string;
  coordinate: Coordinate;
}

export interface HourRange {
  from?: string; // hh:mm
  to?: string; // hh:mm
}

export interface DateRange {
  from?: Date;
  to?: Date;
}

export interface ActionLog {
  userId: ID;
  date: Date;
}

export interface PartnerInfo {
  membershipStatus: string;
  membershipMessage: string;
  membershipMessageType: 'info' | 'warning';
  membershipSubMessage?: string;
  membershipAction: 'pay' | 'stop';
  profileNeedInspect: boolean;
  profileMessage?: string;
  profileErrorMessage?: string;
}

export type PartnerType = 'trainer' | 'driller';

export * from './Device.type';
export * from './User.type';
export * from './Category.type';
export * from './Policy.type';
export * from './Setting.type';
export * from './Template.type';
export * from './Center.type';
export * from './Driller.type';
export * from './Trainer.type';
export * from './Product.type';
export * from './Lesson.type';
export * from './Review.type';
export * from './Membership.type';
export * from './Card.type';
export * from './Payment.type';
export * from './Faq.type';
export * from './Certificate.type';
export * from './Card.type';
export * from './Chat.type';
export * from './Chatroom.type';
export * from './Notification.type';
export * from './Report.type';
export * from './Subway.type';
export * from './Banner.type';
export * from './VirtualNumber.type';
export * from './Game.type';
export * from './Participation.type';
export * from './Notice.type';
export * from './CenterClub.type';
export * from './CenterEvent.type';
export * from './CenterReview.type';
export * from './Statistics.type';
export * from './Movie.type';
export * from './Gallery.type';
export * from './PopupNotice.type';
export * from './PickDayScore.type';
export * from './PickGame.type';

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import {useState} from 'react';
import {Title, useNotify} from 'react-admin';
import {Controller, useForm} from 'react-hook-form';
import {Api, ApiError} from '../utils/api';
import useApp from '../hooks/useApp';

const styles = {
  card: {
    marginBottom: '1em',
    minWidth: 300,
  },
  cardApk: {
    marginBottom: '1em',
  },
  label: {width: '10em', display: 'inline-block'},
  form: {
    padding: '1em 1em 1em 1em',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '1em',
  },
  button: {
    marginBottom: '1em',
    width: '100%',
  },
};

type FormData = {
  newPassword: string;
  currentPassword: string;
};

const ConfigurationPage = () => {
  const notify = useNotify();
  const {apk, apkKcp, apkFaceswap} = useApp();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      await Api.put('/users/me/password', data);
      notify('변경하였습니다.');
    } catch (err) {
      const e = err as ApiError;
      notify(e.message, {type: 'error'});
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card sx={styles.card}>
        <Title title="관리자 설정" />
        <CardContent>
          <Box sx={styles.label}>비밀번호 변경</Box>

          <Box sx={styles.form}>
            <Controller
              control={control}
              name="currentPassword"
              defaultValue=""
              rules={{
                required: '필수 항목입니다.',
              }}
              render={({field: {onChange, onBlur, value}}) => (
                <TextField
                  type="password"
                  sx={styles.input}
                  placeholder="현재비밀번호"
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  error={!!errors.currentPassword?.message}
                  helperText={errors.currentPassword?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="newPassword"
              defaultValue=""
              rules={{
                required: '필수 항목입니다.',
              }}
              render={({field: {onChange, onBlur, value}}) => (
                <TextField
                  type="password"
                  sx={styles.input}
                  placeholder="변경할 비밀번호"
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  error={!!errors.newPassword?.message}
                  helperText={errors.newPassword?.message}
                />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              sx={styles.button}
              onClick={handleSubmit(onSubmit)}>
              {loading && <CircularProgress size={18} thickness={2} />}
              변경하기
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Card sx={styles.card}>
        <Box sx={styles.cardApk}>
          <a href={apkFaceswap?.url}>
            faceswap 테스트 버전 다운로드 {apkFaceswap?.key}
          </a>
          <br />
          <span>
            last updated at:{' '}
            {dayjs(apkFaceswap?.lastModified).format('YYYY-MM-DD HH:mm')}
          </span>
        </Box>
        <Box sx={styles.cardApk}>
          <a href={apk?.url}>테스트 버전 다운로드 {apk?.key}</a>
          <br />
          <span>
            last updated at:{' '}
            {dayjs(apk?.lastModified).format('YYYY-MM-DD HH:mm')}
          </span>
        </Box>
        <Box sx={styles.cardApk}>
          <a href={apkKcp?.url}>KCP 검수버전 다운로드 {apkKcp?.key}</a>
          <br />
          <span>
            last updated at:{' '}
            {dayjs(apkKcp?.lastModified).format('YYYY-MM-DD HH:mm')}
          </span>
        </Box>
      </Card>
    </>
  );
};

export default ConfigurationPage;

import {useEffect, useState} from 'react';
import {create} from 'zustand';
import {Api} from '../utils/api';

interface _Appstate {
  version?: string;
  appVersion?: string;
  s3Url?: string;
  s3StaticUrl?: string;
  faceswapUrl?: string;
  banks?: {
    code: string;
    name: string;
  }[];
  registerAuthCheatCode?: string;
  needAppUpdate?: boolean;
  gameRecruitmentGameCount?: number;
  apk?: {
    lastModified: Date;
    contentLength: number;
    key: string;
    url: string;
  };
  apkKcp?: {
    lastModified: Date;
    contentLength: number;
    key: string;
    url: string;
  };
  apkFaceswap?: {
    lastModified: Date;
    contentLength: number;
    key: string;
    url: string;
  };
  setting?: {
    appIosUrl: string;
    appAndroidUrl: string;
    trainerFreeMonths: number;
    trainerMonthlyPrice: number;
    company: {
      bizNum: string;
      owner: string;
      tongsinNo: string;
      phone: string;
      email: string;
      policyTosUrl: string;
      policyPrivacyUrl: string;
      policyLocationUrl: string;
      policyFinancialUrl: string;
      policyMembershipUrl: string;
      policySettlementUrl: string;
      policyFaceswapUrl: string;
    };
  };
  setApp: (data: any) => void;
}

const _useAppContext = create<_Appstate>((set, _get) => ({
  setApp: data => {
    set({
      version: data.version,
      appVersion: data.appVersion,
      s3Url: data.s3Url,
      s3StaticUrl: data.s3StaticUrl,
      faceswapUrl: data.faceswapUrl,
      banks: data.banks,
      registerAuthCheatCode: data.registerAuthCheatCode,
      needAppUpdate: data.needAppUpdate,
      gameRecruitmentGameCount: data.gameRecruitmentGameCount,
      apk: data.apk,
      apkKcp: data.apkKcp,
      apkFaceswap: data.apkFaceswap,
      setting: data.setting,
    });
  },
}));

const useApp = () => {
  const [loading, setLoading] = useState(false);
  const {
    setApp,
    version,
    appVersion,
    s3Url,
    s3StaticUrl,
    faceswapUrl,
    banks,
    registerAuthCheatCode,
    needAppUpdate,
    gameRecruitmentGameCount,
    apk,
    apkKcp,
    apkFaceswap,
    setting,
  } = _useAppContext();

  useEffect(() => {
    if (version === undefined) {
      _fetchApp();
    }
  }, []);

  const _fetchApp = async () => {
    setLoading(true);
    try {
      const data = await Api.get<any, any>('/app');
      setApp(data);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    version,
    appVersion,
    s3Url,
    s3StaticUrl,
    faceswapUrl,
    banks,
    registerAuthCheatCode,
    needAppUpdate,
    gameRecruitmentGameCount,
    apk,
    apkKcp,
    apkFaceswap,
    setting,
  };
};

export default useApp;
